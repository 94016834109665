.top {
  padding-top: 2em;
}

.typewriter h1 {
  text-align: center;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: clamp(1.5rem, 4vw, 3rem);
  color: #029A00;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 95vw;
  margin: 0 auto;
  padding-right: 0.15em;
}

.typewriter h1::after {
  text-align: center;
  margin: 0 auto;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.15em;
  background-color: orange;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to { 
    background-color: transparent;
  }
  50% { 
    background-color: orange;
  }
}
