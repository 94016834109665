.content {
  /* position: relative; */
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.items, .a {
  font-size: 30px;
  
}

.a:link {
  text-decoration: none;
}

.items {
  position: relative;
  color:white;
  top: 0;
  padding: 0.5em;
}
.items:hover {
  color: red; 
}

.badgeTHM {
  padding-top: 0em;
  border: none;
}

.eyeWindow {
  margin-bottom: 11em;
}