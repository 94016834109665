html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

a {
  animation-name: secret-puzzle;
  animation-duration: 2s;
}

@keyframes secret-puzzle {
  0%   {background-color:red; left:0px; top:0px;}
  10%  {background-color:purple; left:0px; top:200px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:red; left:0px; top:0px;}
}
